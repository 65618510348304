const getters = {
	// 通过参数编号，获取相应值
	getVipParamterByParNo:(state)=>{
		return (parNo,def,valuename,protype)=>{
			
			valuename = valuename || 'value1';
			// 获取企业参数
			function getValue(state,parNo,def,valuename){
				let obj = state.vipParamterList || {};

				if (obj.hasOwnProperty(parNo) && obj[parNo].hasOwnProperty(valuename)) {
					if(typeof(def) == 'string'){
						return obj[parNo][valuename];
					}
					return +obj[parNo][valuename];
				} else {
					return def;
				}
			}
			// 获取10991参数  value1 ，若配置protype，则 取该业务单独行程配置
			function getValue1For10991(state, def,proType) {
				
				let value10991 = getValue(state, "10991", def,'value1');
					// 10901	国内机票
				// 10902	国际机票
				// 10903	国内酒店
				// 10904	火车票
				// 10905	保险
				// 10906	用车
				let paramNoForobj = {
					10901:20070, 
					10903:20071, 
					10904:20072,
					10906:20073,
				}
				// 处理按单独业务开启逻辑， 若开启，则取单独行程控制的参数 value2 管控;
				let paramNoForProType = paramNoForobj[proType];
				if(paramNoForProType){
					let curValue1 = getValue(state, paramNoForProType, 0,'value1');
					if(curValue1 ==1){
						// 有值取值，无值取 前端设置默认值
						let curValue2 =getValue(state, paramNoForProType, def,'value2'); 
						value10991 = curValue2;
					}
				}
			
				return value10991
			}

			// 单独处理10991
			if(parNo == '10991'){
				let p10901Value = getValue(state,parNo,def,valuename);
				if(valuename == 'value1'){
					p10901Value = getValue1For10991(state,def,protype);
				}
				return p10901Value
			}



			return getValue(state,parNo,def,valuename)
		}
	}
}

export default getters;


// 单独处理10991 说明
/**
 * 		任务号：11321 【正式环境】【紧急更新】广西中烟的差标管控传入逻辑修改
 * 
 * 			出差单行程管控，业务单独管控设置：
				【机票】 是否开启单独管控：不开启(默认) 0 ， 开启 1 行程管控类型：【select 框选择】
				【酒店】 是否开启单独管控：不开启(默认)0 ， 开启 1 行程管控类型：【select 框选择】
				【火车票】是否开启单独管控：不开启(默认)0 ， 开启 1 行程管控类型：【select 框选择】
				【用车】是否开启单独管控：不开启(默认)0 ， 开启 1 行程管控类型：【select 框选择】

				【select 框选择】枚举：
				不可更改： 0 （出发、到达城市不可以修改，严格按照行程出行）
				可任意更改：1 （出发、到达城市可以随意修改）
				出发、到达城市可以随意修改：2 （出发、到达城市，只能从行程中包含的城市中选取）
				出发到达城市限制可选：3 （出发、到达城市，只能分别从行程中的出发、到达城市中选取）

				后台配置参数编号： 都有value1 和 value2
				20070 机票
				20071 酒店
				20072 火车票
				20073 用车
 * 
 * **/ 