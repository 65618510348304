/**
 *  拦截器：
 * 生成基础axios对象，并对请求和响应做处理
 * 前后端约定接口返回解构规范
 *
 *    成功返回值
 *    {
 *    data: {}
 *	  errCode: "1"
 *	  errMsg: "success"
 *	  httpStatus: 200
 *	  timestamp: 1629099657883
 *    }
 *    失败返回值
 *    {
 *	    errCode: "3901"
 *	    errMsg: "用户未登录，无权限访问"
 *	    httpStatus: 500
 *	    timestamp: 1629101274198
 *	    tips: "error 系统异常"
 *   }
 *
 *    接口常规报错、网络报错、系统超时、权限认证等做拦截处理
 *
 *
 */
import axios from "axios";
import { Message } from "element-ui";

// 创建一个独立的axios实例
const service = axios.create({
  // 设置baseUr地址,如果通过proxy跨域可直接填写base地址
  baseURL: "/",
  // 定义统一的请求头部
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=UTF-8",
  },
  // 配置请求超时时间
  // timeout: 30000, 180000
  timeout: 30000,
  // 如果用的JSONP，可以配置此参数带上cookie凭证，如果是代理和CORS不用设置
  // withCredentials: true
});
// 请求拦截
service.interceptors.request.use((config) => {
  // 自定义header，可添加项目token
  var token =
    window.localStorage.getItem("access_token") ||
    window.sessionStorage.getItem("access_token") ||
    "";
  if (!config.noNeedToken && !token) {
    window.location.href = "/tmcobt/#/login";

    return Promise.reject({
      code: "400",
      tips: "当前用户没有登录或登录失效，请重新登录",
    });
  }
  config.headers.token = token;
  config.headers["y-cli"] = 10503;
  return config;
});
// 返回拦截
service.interceptors.response.use(
  (response) => {
    // 获取接口返回结果
    const res = response.data;
    // code为0，直接把结果返回回去，这样前端代码就不用在获取一次data.
    if (res.errCode == 1) {
      return res;
    } else if (res.errCode == 3901) {
      // 登录失效

      // 3901是未登录状态码
      var token =
        window.localStorage.getItem("access_token") ||
        window.sessionStorage.getItem("access_token");

      // 防止未登录 直接使用路由进入页面（可能存在单点登录问题，后期沟通）
      if (!token) {
        window.location.href = "/tmcobt/#/login";
      }

      // 处理多条登录失效的问题
      if (token) {
        window.localStorage.removeItem("access_token") ||
          window.sessionStorage.removeItem("access_token");
        Message.error("会话失效，请重新登录");
        // 也可使用router进行跳转
        window.location.href = "/tmcobt/#/login";
      }

      return res;
    } else if (res.errCode == 3003) {
      // 入参不合法
      Message.error(res.tips || res.errMsg);
    } else if (!res.errCode) {
			Message.error(res.tips || res.errMsg);
		} else {
      return res;
    }
  },
  (error) => {
    if (error.message.includes("timeout")) {
      // 判断请求异常信息中是否含有超时timeout字符串
      // Message.error('请求超时，请稍后再试！')
      return Promise.reject({ code: "500", tips: "请求超时，请稍后再试" });
      // return Promise.reject(error);          // reject这个错误信息
    }
    // Message.error('网络请求异常，请稍后再试')
    // return Promise.reject(error);
  }
);
export default service;
