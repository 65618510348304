<template>
	<!-- && (showCostCenter || showProject) -->
	<el-card class="ti-main" >
		<p class="textLeft title-name">差旅信息</p>
		
		<el-form ref="travelInfoFormVaild">
      <div class="ti-title textLeft" v-if="ccsqdNo" >
      	<span>· 出差申请单号</span>
      	<span class="spanlr">{{ccsqdNo}}</span>
      </div>
			<div class="ti-title textLeft" v-show="JSON.stringify(policy) != '{}'">
				<div class="clbz-clbzText" v-if="policy.mulPerson == 1">
					<!-- <span>{{ policy.mulPerson == 1 ? '已开启' : '未开启'}}多人差标模式</span> -->
					<span>已开启多人差标模式</span>
				</div>
				<div class="clbz-user" v-show="policy.mulPerson != 1">
					本次预订采用<span style="opacity: 1;color: #006BB9;">{{ clbzMapCopy.useNameclbz }}</span>的差旅标准
				</div>
				<div class="clbz-user" v-show="policy.mulPerson == 1">
					本次预订采用<span style="opacity: 1;color: #006BB9;">入住人{{ policy.mulPersonStandardDesc }}</span>的差旅标准
				</div>

				<!-- <span>· 本次预订使用</span>
				<span class="spanlr">{{clbzMapCopy.useNameclbz}}</span>
				<span>差旅标准</span> -->
			</div>
			<!-- 去程 -->
			<div v-show="hasShowWei(clbzMapCopy,'violateItem','violateCode')" >
				<el-form-item label="违背事项" label-position="top">
					<el-input  
					 class="font15  borderStyle" 
					 placeholder="违背事项" 
					 v-model="clbzMapCopy.violateItem" 
					 :disabled="true"
					 ></el-input> 
				</el-form-item>
				<el-form-item label="违背原因" label-position="top" v-show="clbzMapCopy.reasonDesc">
					<el-input  
					class="font15  borderStyle"
					placeholder="违背原因" 
					v-model="clbzMapCopy.reasonDesc"
					@click.native="getWbReason(1)"
					readonly
					></el-input>
				</el-form-item>
				<!-- 违背弹窗选择 -->
				<yj-violation
					:vltShow="isVltShow" 
					:vltReason="clbzMapCopy.violateItem" 
					:byNumber="proType" 
					:reasonCode="clbzMapCopy.reasonCode"
					:reasonDesc="clbzMapCopy.reasonDesc"
					:weiType="1"
					@chooseReason="chooseReason" 
					@cancelReason="cancelReason"></yj-violation>
			</div>
			<!-- 返程 clbzMapCopy.violateItem1 -->
			<div v-show=" hasShowWei(clbzMapCopy,'violateItem1','violateCode1')" >
				<el-form-item label="违背事项" label-position="top">
					<el-input  
					class="font15  borderStyle" 
					placeholder="违背事项" 
					v-model="clbzMapCopy.violateItem1"
					:disabled="true"
					></el-input> 
					
				</el-form-item>
				<el-form-item label="违背原因" label-position="top">
					<el-input  
					class="font15  borderStyle" 
					placeholder="违背原因" 
					automatic-dropdown
					v-model="clbzMapCopy.reasonDesc1"
					@click.native="getWbReason(2)"
					></el-input>
				
				</el-form-item>
				<!-- 违背弹窗选择 -->
				<yj-violation
					:vltShow="isVltShow1" 
					:vltReason="clbzMapCopy.violateItem1" 
					:byNumber="proType" 
					:reasonCode="clbzMapCopy.reasonCode1"
					:reasonDesc="clbzMapCopy.reasonDesc1"
					:weiType="2"
					@chooseReason="chooseReason" 
					@cancelReason="cancelReason"></yj-violation>
				
			</div>
			<div>
				<!-- 出差项目 -->
				<el-form-item label="出差项目" label-position="top" v-if="showProject">
					<div slot="label">
						<div style="line-height: 32px;">
							<span style="color:red;font-size: 14px;" v-if="projectRequired">*</span>
							出差项目
							
						</div>
						
					</div>
					<el-select
					
						class="bfb-w100"
						v-model="chooseProject.projectName"
						value-key
						remote
						:loading="loadingProjectList"
						reserve-keyword
						filterable
						automatic-dropdown
						:remoteMethod="getProjectList"
						@focus="getProjectList"
						clearable
						@change="getCurrentProject" 
						popper-class="project-list"
						:popper-append-to-body="false"
						>
							<div style="max-height:360px;text-align: left;">
								<el-option
														
									v-for="item in projectPager.list"
									:key="item.id"
									:label="item.projectName"
									:value="item"
								>  
								</el-option>
								
							</div>
							<el-pagination
								style="text-align: left;"
								:page-size="projectPager.count"
								:current-page="projectPager.pageNum"
								@current-change="getProjectCurrentPage"
								@size-change="getProjectCurrentSize"
							    :total="projectPager.total">
							 </el-pagination>
						
						</el-select>
				</el-form-item>
				<!-- 成本中心 -->
				<div class="costcenter-div flex justify-content-space-between flex-wrap" v-if="showCostCenter">
					<div style="width: 340px;"  v-for="(cxrItem, cxrIndex) in cxrListForCostCenter" :key="cxrIndex">
						<el-form-item
						  label="成本中心(费用承担部门)"
						  label-position="top"
						>
						<div slot="label">
							<div style="line-height: 32px;">
								<span style="color:red;font-size: 14px;" v-if="cxrIndex == 0 && costCenterRequired">*</span>
								{{cxrIndex > 1? '':cxrIndex == 1?'&nbsp;': "成本中心(费用承担部门)"}}
								
							</div>
							
						</div>
						<div class="" style="width: 100%;">
								 <el-tooltip :disabled="true"  effect="dark" :content="cxrItem.cxrName" placement="top-start" :open-delay="600">
								      <span class="ti-m-ccr" >
										<span v-if="proType==10901 || proType==10902">乘机人</span>  
										<span v-if="proType==10903 || proType==10910">入住人</span>  
										<span v-if="proType==10904">乘客</span>  
										
										{{cxrIndex + 1}}</span>
								    </el-tooltip>
								
								<el-tooltip :disabled="true" effect="dark" :content="cxrItem.costCenterName" placement="top" :open-delay="600">
								     <!-- remote
										@clear="clearSearch"
								      -->
									
									
									   
										 <el-select
									  		ref="selectblur"
										 	class="bfb-w100"
										 	v-model="cxrItem.costCenterName"
										 	value-key
										 	remote
										 	:loading="loadingCostCenterList"
										 	reserve-keyword
										 	filterable
										 	automatic-dropdown
										 	clearable
										 	@visible-change="(bool)=>{getCostCenterListInit(cxrItem,cxrIndex,bool)} "
											:remoteMethod="(keyword)=>{getCostCenterListMiddleFn(cxrItem,cxrIndex,keyword)}"
										 	@change="getCurrentCostCenter" 
											
										 	popper-class="project-list"
										 	:popper-append-to-body="false"
										 	>
											 <div style="text-align:left;padding: 5px 15px;" v-if="showCostcenterOtherDept">
												<el-radio v-model="grpSelect"  label="2">本单位承担</el-radio>
												<el-radio v-model="grpSelect"  label="1">其他单位承担</el-radio>
											</div>
											<div v-if="grpSelect == 1">
												<el-row :gutter="20">
													<!--村数据-->
													<el-col :span="24">
													
														<!-- 组织树 -->
														<div class="head-container" style="min-height:360px;max-height: 800px;width: 700px;">
															<el-tree ref="tree" 
																:data="treeDeptList" 
																:props="defaultProps" 
																:default-expanded-keys="treeData" 
																:expand-on-click-node="false"
																:load="loadNode" 
																lazy 
																node-key="deptId" 
																:filter-node-method="filterNode" 
																@node-click="handleNodeClick" />

																
														</div>
													</el-col>
												</el-row>

											</div>
											<div v-show="grpSelect != 1">
												<div style="max-height:360px;width: 700px;text-align: left;">
										 			<el-option
														v-for="item in costCenterList"
														:key="item.costCenterId"
														:label="item.costCenterName"
														:value="item.costCenterId">  
										 			 </el-option>
										 		</div>
												<!-- 	:current-page="costCenterPager.pageNum" -->
										 		<el-pagination
										 			style="text-align: left;"
										 			:page-size="costCenterPager.count"
										 		
													:current-page.sync="costCenterPager.pageNum"
										 			@current-change="getCostCenterCurrentPage"
										 			@size-change="getCostCenterCurrentSize"
													@click.stop.native="()=>{}"
										 			:total="costCenterPager.total">
										 		</el-pagination>
											</div>		
										 		
										</el-select>
								</el-tooltip>
								

							
						</div>
							
						
						</el-form-item>
					</div>
					
				</div>
				<!-- 审批规则 -->
				
				<el-form-item label="审批规则" label-position="top" v-if="isShowApplyRule && showApplyRule">
					<el-select 
						v-model="chooseSpgz.gzmc" 
						:loading="loadingSpgzList"
						value-key
						automatic-dropdown
						@change="getCurrentSpgz" 
						:placeholder="!isWuXuShenPi?'请选择审批规则':'无需审批'" 	
						class="bfb-w100"
						>
						<!-- @focus="getSpgzList()" -->
					    <el-option
					      v-for="item in spgzList"
					      :key="item.gzId"
					      :label="item.gzmc"
					      :value="item">
					    </el-option>
					</el-select>  
				</el-form-item>
			</div>
		</el-form>	
		
		
			
			
	</el-card>
</template>

<script>
	export default{
		name: "TrainTravelInfo",
		props:{
			// 出行人列表
			cxrList:{
				type:Array,
				default (){
					return []
				}
			},
			// 业务类型  11001-11006 机票   11007-11008 酒店  11009-11011 火车票 
			orderType:{  // 单据类型
				type: Number,
				default: 11001
			},
			// 默认项目
			defaultPoject:{
				type:[Object],
				default (){
					return {}
				}
			},
			// 出差申请单号
			ccsqdNo:{
				type:[Number,String],
				default (){
					return '';
				}
			},
			//违背事项和违背原因
			clbzMap:{
				type:[Object],
				default (){
					return {
						
					}
				}
			},
			// 正常单 订单金额
			ticketPrice:{
				type:[ Number, String],
				default: 0 ,
			},
			// 业务类型 火车 10904 机票 10901 酒店 10903
			proType:{
				type: Number,
				default: 10904,
			},
			// 差旅标准 
			policy:{
				type:[Object],
				default (){
					return {
						
					}
				}
			},
			showApplyRule: {
				type: Boolean,
				default: true,
			}
		},
		data() {
			return {
				isVltShow: false, // 重选违背原因
				isVltShow1:false,// 返程违背原因
				
				
				// 成本中心模块
				cxrListForCostCenter:[],
				costCenterList: [], // 可选择的成本中心
				loadingCostCenterList:false, // 加载成本中心
				costCenterPager:{
				    count: 10,
				    pageNum: 1,
				    total:0,
					projectId: '',// 项目id。如果选了项目，则必传 ,
					travelEmpId: '', // 出行人id。如果在订单填写页面，则必传
					list:[],
					map:{},
					filter: {
						keywords:''
					},
				},
				chooseCostCxrObj: {}, // 选中的成本中心的出行人
				
				clbzMapCopy:{},
				// 项目中心模块
				// 项目查询参数
				projectPager:{
				    count: 10,
				    pageNum: 1,
				    total:0,
				    list:[],
				    map:{},
				    filter: {
				    	keywords:''
				    },
				},
				loadingProjectList:false, // 加载是否项目
				// 选中的项目
				chooseProject:{
					id :'',    // 项目id
					projectCode:"",   // 项目名称
					projectName:''    // 项目代码
				},
				
				// 审批规则模块
				spgzList: [], // 审批规则列表
				loadingSpgzList:false, // 加载审批规则列表
				// 选中的审批规则
				chooseSpgz:{
					gzId:'', // 审批规则id
					gzmc:'', // 审批规则名称
					gzdm:''  // 审批规则代码
				},
				tripType:1,
				
				isWuXuShenPi:true,// 是否无需审批
				spkzMap:{},// 企业审批规则

				grpSelect:'2', // 1-其他单位承担（选择整个集团成本中心） 2或空 本企业下成本中心
				
				// 模拟数据
				// analoDdata_01: [
				// 	{
				// 	"deptId": 100,
				// 	"parentId": 0,
				// 	"parentName": null,
				// 	"ancestors": "0",
				// 	"deptName": "XX街道",
				// 	"children": [],
				// 	"existSub": true, //是否还存在下级
				// 	}
				// ],
	
				// 村名称
		
				defaultProps: {
					children: 'children',
					label: 'costCenterName',
					isLeaf: 'leaf',//指定节点是否为叶子节点，仅在指定了 lazy 属性的情况下生效
				},
				// 默认展开的节点的 key 的数组
				treeData: [],
				treeDeptList:[],

				
			}
		},

		computed:{
			// 是否显示违背
			isShowWei(){
				let bool = false;
				if(this.clbzMapCopy.violateItem){
					bool = true;
				}
				return bool
			},
			// 是否显示审批规则项
			isShowApplyRule(){
				var bool = false;
				var sfkq = this.$common.getSpgzSfkq(this.spkzMap,this.orderType,0);
				if(sfkq == 1){ // 因公正常流程都需审批
					bool = true
				}else if(sfkq == 2 && this.isShowWei){ // 因公正常单违背的需要审批
					bool = true
				}

				// 如果企业无需审批，但是获取审批规则，有数据，则需要审批
				if(this.spgzList.length>0){
					bool = true;
				}
				let kuaDept =  this.$common.getVipParamterByParNo(this.$store,'20056',0)
				if(kuaDept == 1){
					bool = true
				}
				
							
				return bool	  
			},
			// 登录企业信息
			userInfo(){
				return this.$common.getUserInfo()
			},
			
			// 出差申请单所有信息
			chooseCcsqdObj(){
				let chooseCcsqdObj = this.tripType==1?this.$common.sessionget('chooseCcsqdObj'):{};
				return chooseCcsqdObj || {}
			},

			bookStyle(){
				let bookStyle = this.$common.getBookStyle(this.$store,this.proType)
				return bookStyle;
			},
			//显示成本中心
			showCostCenter(){ 
				let param = this.$common.getVipParamterByParNo(this.$store,20002,3)
				return  param==1 && this.tripType==1;
			},
			// showCostcenterOtherDept:0, //// 是否展示其他公司的成本中心1-展示 0-不展示，默认0
			showCostcenterOtherDept(){
				let param = this.$common.getVipParamterByParNo(this.$store,20052,0)
				return  param==1 && this.showCostCenter;
			},
			//显示项目
			showProject(){ 
				let param = this.$common.getVipParamterByParNo(this.$store,20003,3);
				return  param==1 && this.tripType==1;
			},
			 //出差项目 是否开启必填 0不开启  1开启
			projectRequired(){ 
				let param = this.$common.getVipParamterByParNo(this.$store,10996,3)
				return param==1  && this.tripType==1 && this.showProject
			},
			 // 成本中心 是否开启必填 0不开启  1开启
			costCenterRequired(){ 
				let param = this.$common.getVipParamterByParNo(this.$store,10995,3)
				return param==1  && this.tripType==1 && this.showCostCenter
			},
		},
		watch:{
			cxrList:{
				handler(val,oldVal){
					this.cxrListForCostCenter = this.$common.deepCopy(this.cxrList);
				},
				immediate: true, // 立即执行
				deep:true
			},
			clbzMap:{
				deep:true, // 深度监听
				immediate: true, // 立即执行
				handler (val, oldVal) {
					this.clbzMapCopy = this.$common.deepCopy(val)
				}
			},
			// tab 切换
			grpSelect(val){

				if(val != 1){
					this.costCenterPager.pageNum = 1;
				
					this.costCenterPager.filter.keywords = '';
					this.costCenterPager.cxrItem = this.costCenterPager.cxrItem || {};
					this.costCenterPager.cxrIndex =  this.costCenterPager.cxrIndex || 0;

					this.getCostCenterList(this.costCenterPager.cxrItem,this.costCenterPager.cxrIndex);
				}else{
					// this.getTreeData();
				}

			},
			isWuXuShenPi(val){
				if(val){
					this.$emit('changeWuXuShenPi',val)
				}
			},
		

		},
		
		mounted(){
			// 初始化项目
			this.chooseProject={
				id :this.defaultPoject.id || '',    // 项目id
				projectCode:this.defaultPoject.projectCode || '',   // 项目名称
				projectName:this.defaultPoject.projectName || ''    // 项目代码
			}
			this.projectPager.filter.keywords = this.chooseProject.projectName;
			// 获取企业设置规则
			this.getCorpSettingRule();
			// 初始化出行人信息，用于成本中心的选择
			// this.cxrListForCostCenter = this.$common.deepCopy(this.cxrList)
			
			this.getSpgzList();
		
		},
		methods: {
			hasShowWei(wItem,attrname,attrCodeName){
				let bool = false;

				if(wItem[attrname]){
					bool = true;
					// if(wItem[attrCodeName] == '11106'){
					// 	bool = false;
					// }
				}
				return bool
			},
			//  关于树懒加载 (组件加载完成后，会主动触发一次)，后续则是点击时候会触发,
			// 或者通过default-expanded-keys默认展开的节点的 key 的数组来触发这个方法，实现指定加载哪几层的数据。
			loadNode(node, resolve) {
				// console.log(node, 'node')
				// 如果展开第一级节点，从后台加载一级节点列表
				if (node.level == 0) { // 获取第一层 也就是 analoDdata_01的数据
					// console.log('第一级节点')
					const queryParams = {}
					this.loadNodeHandle(resolve, queryParams, 1) //1获取模拟数据需要，实际可不用传递
				}
				// 如果展开其他级节点，动态从后台加载下一级节点列表
				if (node.level >= 1) { // 获取第一层的下级 也就是 analoDdata_02的数据
					// console.log('其他级节点')
					const queryParams = {}
					// 根据当前节点的id，查询他下级的相关节点
					queryParams.parentId = node.key
					this.loadNodeHandle(resolve, queryParams, 2)// 2获取模拟数据需要，实际可不用传递
				}
			},
			async loadNodeHandle(resolve, queryParams, type) {
				let queryObj = {
			
					  parentId :queryParams.parentId,
					  pageNum:1,
					  count:1000,
					};
				if(type == 1){
					this.treeDeptList = [];
					queryObj.keyword =  this.costCenterPager.filter.keywords;
				}

				let cxrItem = this.costCenterPager.cxrItem || {};
				let cxrIndex = this.costCenterPager.cxrIndex ||0;
		


				// 根据实践项目调用接口
				 let list = await this.$conn.getConn('commu.costCenterList3')(queryObj).then(res => {
				//   console.log(res, '关于树懒加载')
						let data = res.data || {};
						let page = data.page || {};
						let newlist = page.list|| [];
						newlist = newlist.map(item=>{
							return {
								...item,
								cxrIndex,
								parentId:item.parentId || '0',
								costCenterId: item.costCenterId || item.deptId,
								children:[],
								leaf : item.hasLeaf != 1,
							}
						})
			
				  return newlist
				})
				if(type == 1){
					this.treeDeptList = list;
				}
				
				// 根据某些条件，获取到需要默认展开的节点的 key 的数组 this.treeData; 
				// this.treeData 中的id对应的节点，都会默认加载loadNode方法获取其下级数据，并展开；
				if (list.length == 1) {
					list.forEach(element => {
					this.treeData.push(element.deptId)
					})
				}
				

				return resolve(list)
			},



			// 过滤函数
			filterNode(value, data) {
				// console.log(value, data)
				if (!value) return true
				return data.deptName.indexOf(value) !== -1
			},
			// 左侧网格树-节点单击事件
			handleNodeClick(costCenterObj) {
				// console.log(costCenterObj, '左侧网格树-节点单击事件')
				
				if(costCenterObj.parentId && costCenterObj.parentId !=0 &&costCenterObj.hasLeaf != 1){
					let cxrIndex = this.costCenterPager.cxrIndex || 0;
					this.cxrListForCostCenter[cxrIndex]['costCenterCode'] = costCenterObj['costCenterCode'];
					this.cxrListForCostCenter[cxrIndex]['costCenterName'] = costCenterObj['costCenterName'];
					this.cxrListForCostCenter[cxrIndex]['costCenterId'] = costCenterObj['costCenterId'];
					
					this.$refs['selectblur'][0] && this.$refs['selectblur'][0].blur();
					this.$emit('getCxrList',this.cxrListForCostCenter);	

					this.getSpgzList()
				}else{
					// this.$message({
					// 	    type: 'error',
					// 	    message: '当前节点不可选择，请展开，选择下一节点！'
					// 	});
					
			
				}

	
				
			},



			getCorpSettingRule(){
				var query = this.$conn.getConn('commu.getUserTravelInfo');
				query({},res=>{
					var data = res.data||{};
					var approvalBean = data.approvalBean||{};
					this.spkzMap = approvalBean.spkzMap||{};
				},err=>{
					
				})
			},
			// 重选违背原因
			getWbReason(type){
				if(type == 1){
					this.isVltShow = true;
				}else{
					this.isVltShow1 = true;
				}
				// if(this.clbzMapCopy.reasonDesc){
				// 	this.isVltShow = true;
				// }
				
			},
			// 选择违背原因
			chooseReason(obj){
				if(obj.weiType==2){
					this.isVltShow1 = false;
				}else{
					this.isVltShow = false;
				}
				
				this.$emit('selectReason',obj)
			},
			// 关闭违背原因
			cancelReason(){
				this.isVltShow = false;
			},	
			// 验证参数  
			//  项目和成本中心是否必填、审批规则是够已经选择！
			validTravelInfo(){
				var obj = {
					bool:true,
					msg:"",
				}
				// 验证项目
				console.log(this.chooseProject)
				console.log(this.defaultPoject);
				
				if(this.projectRequired && this.showProject && !this.chooseProject.projectName){
				   obj.msg = "当前项目必填，项目名称不能为空！";
				   obj.bool = false;
				   return obj
				 }
				 
				// 成本中心必填验证
				 if(this.showCostCenter && this.costCenterRequired ){
					 var costCenterValidObj = this.validCostCenter();
					 if(costCenterValidObj.wxCount>0){
						 var psgStr = costCenterValidObj.wxIndexArr.join('、')
						 obj.msg = `当前成本中心必填，${psgStr}的成本中心没有选择`;
						 obj.book = false;
						 return obj
					 }
				   
				  
				 }
				 // 审批规则验证  如果需要审批，审批规则id为空，则验证提示
				 if(!this.isWuXuShenPi && !this.chooseSpgz.gzId && this.showApplyRule){
					 obj.msg = "请选择审批规则！";
					 obj.bool = false;
					 return obj
				 }
				 
				return obj
			},
			// 验证成本中心
			validCostCenter(){
				var yxCount = 0, wxCount = 0,wxIndexArr=[];
				if(this.cxrListForCostCenter.length>0){
					this.cxrListForCostCenter.forEach((cxrCostItem,cxrCostIndex)=>{
						if(cxrCostItem.costCenterId){
							yxCount++;
						} else{
							wxCount++;
							wxIndexArr.push("乘客" + (cxrCostIndex +1));
						}
					})
				}
				return {yxCount,wxCount,wxIndexArr}
			},
			// 出差项目列表
			getProjectList(a) {
				let keywords = '';
				// 非点击状态下，可能传值
				if(this.$common.isString(a)){
					keywords = a;
				}else{
					this.projectPager.pageNum = 1;
				}
				this.projectPager.filter.keywords = keywords;
				let obj = {
					    "count": this.projectPager.count,
					    "keywords": keywords,
					    "pageNum": this.projectPager.pageNum,
				}
				this.loadingProjectList = true;
				this.$conn.getConn('commu.project')(obj,(res)=>{
					let data = res.data || {};
					this.projectPager.total = data.total || 0;
					let list = data.list || [];
					list = list.map(iItem=>{
						return {
							...iItem,
							projectId:iItem.projectId || iItem.id,
						}
					})
					this.projectPager.list = list;
					
					this.loadingProjectList = false;
					
				},e=>{
					this.projectPager.total = 0;
					this.projectPager.list = [];
					this.loadingProjectList = false;
				})
			
				
			},
			// 获取选中的出差项目
			getCurrentProject(chooseItem){
				this.chooseProject.id = chooseItem.id;
				this.chooseProject.projectId = chooseItem.projectId;
				this.chooseProject.projectCode = chooseItem.projectCode;
				this.chooseProject.projectName = chooseItem.projectName;
				this.$emit('chooseProject',this.chooseProject)
				
				// 项目修改清除成本中心信息
				this.clearCostCenter();

			
			},
			// 获取项目分页信息
			getProjectCurrentPage(pageNum){
				this.projectPager.pageNum = pageNum;
				this.getProjectList(this.projectPager.filter.keywords);
			},
			// 获取当前size
			getProjectCurrentSize(pageSize){
				this.projectPager.count = pageSize;
				this.getProjectList(this.projectPager.filter.keywords);
			},
			// 成本中心初始化
			getCostCenterListInit(cxrItem,cxrIndex,bool) {
				if(this.grpSelect !=1){
					// 显示
					if(bool){
						this.chooseCostCxrObj = {
						cxrItem: cxrItem,
						cxrIndex: cxrIndex,
					}
						this.costCenterPager.pageNum = 1;
						this.costCenterPager.filter.keywords = '';
						this.costCenterPager.cxrItem = cxrItem;
						this.costCenterPager.cxrIndex = cxrIndex;

						this.getCostCenterList(cxrItem,cxrIndex);
					}else{ // 隐藏时
						this.costCenterPager.pageNum = 1;
						this.costCenterPager.filter.keywords = '';
					}
				}
				

			
			},
			getCostCenterListMiddleFn(cxrItem,cxrIndex,keyword){
				this.costCenterPager.pageNum = 1;
				this.costCenterPager.filter.keywords = keyword;

				this.costCenterPager.cxrItem = cxrItem;
				this.costCenterPager.cxrIndex = cxrIndex;

				if(this.grpSelect == 1){
					new Promise((resolve)=>{
						this.loadNodeHandle(resolve, {}, 1)// 2获取模拟数据需要，实际可不用传递
					})
					
				}else{
					this.getCostCenterList(cxrItem,cxrIndex)
				}

				
				
			},
			// 成本中心列表
			getCostCenterList(cxrItem,cxrIndex) {
					let queryObj = {
					  "projectId": this.chooseProject.id,
					  "travelEmpId": cxrItem.empId,
					  "pageNum": this.costCenterPager.pageNum,
					  "count": this.costCenterPager.count,
					  "keyword": this.costCenterPager.filter.keywords,
					  grpSelect:this.grpSelect,
					};
					this.$conn.getConn('commu.costCenterList2')(queryObj).then((res)=>{
						let data = res.data || {};
						let page = data.page || {};
						let list = page.list|| [];
						
						this.costCenterList = list.map(item => {
							item.cxrIndex = cxrIndex;
							return item;
						});	
						
						this.costCenterPager.total = page.total || 0;
						
					})
				
			},
			// 获取当前size
			getCostCenterCurrentSize(pageSize){
				this.costCenterPager.count = pageSize;
				console.log(this.costCenterPager)
				
				this.getCostCenterList(this.chooseCostCxrObj.cxrItem,this.chooseCostCxrObj.cxrIndex);
			},
			// 获取成本中心分页信息
			getCostCenterCurrentPage(pageNum){
				this.costCenterPager.pageNum = pageNum;
				console.log(this.costCenterPager)
				this.getCostCenterList(this.chooseCostCxrObj.cxrItem,this.chooseCostCxrObj.cxrIndex);
			},

			// 选中的成本中心内  key-value
			getCurrentCostCenter(costId){
				let costCenterObj = {};
				let filterList = this.costCenterList.filter(costItem=>{
					return costItem.costCenterId == costId
				}) 
				if(filterList.length>0){
					costCenterObj = filterList[0] || {};
					costCenterObj = costCenterObj || {
						cxrIndex: this.chooseCostCxrObj.cxrIndex,
					};
				
					let cxrIndex = costCenterObj.cxrIndex;
					this.cxrListForCostCenter[cxrIndex]['costCenterCode'] = costCenterObj['costCenterCode'];
					this.cxrListForCostCenter[cxrIndex]['costCenterName'] = costCenterObj['costCenterName'];
					this.cxrListForCostCenter[cxrIndex]['costCenterId'] = costCenterObj['costCenterId'];
							
					this.$emit('getCxrList',this.cxrListForCostCenter)
				}
				
				this.getSpgzList()
			},
			// 清空选中的成本中心
			clearCostCenter() {
				for (let item of this.cxrListForCostCenter) {
					delete item.costCenterCode
					delete item.costCenterName
					delete item.costCenterId
				}
				this.$emit('getCxrList',this.cxrListForCostCenter)

				this.getSpgzList()
			},
			// 审批规则模块
			getSpgzList(){
				// 出差申请单模式
				this.loadingSpgzList = true;
				
				if(this.bookStyle == 2){
					this.getTravelApprovalRules();
				}else{ // 单订模式
					this.getSingleApprovalRules();
				}
				
			},
			// 获取出行人中的员工id
			getCxrEmpIdArr(){
				let  cxrEmpIdArr = [];
				for(var k=0;k<this.cxrList.length;k++){
					if(this.cxrList[k]['empId']){
						cxrEmpIdArr.push(this.cxrList[k]['empId'])
					}
				}
				return cxrEmpIdArr
			},
			// 获取出行人中的成本中心id集合
			getCostCenterIdArr(){
				let  arr = [];
				for(var k=0;k<this.cxrListForCostCenter.length;k++){
					arr.push(this.cxrListForCostCenter[k]['costCenterId'] || '')
				}
				return arr
			},
			
			// 出差申请单模式下匹配审批规则
			getTravelApprovalRules(){
				
				let objParams = {};
				let cxrEmpIdArr = this.getCxrEmpIdArr();
				let costCenterIdArr = this.getCostCenterIdArr();
				objParams = {
				  "bookEmpId": this.userInfo.empInfo.empId,
				  "corpId":  this.userInfo.empInfo.corpId,
				  "fcny":  this.ticketPrice || 0,
				  "ifViolate": this.isShowWei?1:0,
				  "orderType": this.orderType, // 单据类型
				  "projectId": this.chooseProject.id,
				  "routeId": this.chooseCcsqdObj.routeId,
				  "travelEmpIds": cxrEmpIdArr.join(','),
				  costCenterId:costCenterIdArr[0]
				}
				this.$conn.getConn('commu.travelRouteMatchSpgz')(objParams,(res)=>{
					this.spgzSuccessAjax(res)
					},(err)=>{
						this.spgzErrorAjax(err)
					})
			},
			// 单订模式下批配审批规则
			getSingleApprovalRules(){
				let cxrEmpIdArr = this.getCxrEmpIdArr();
				let costCenterIdArr = this.getCostCenterIdArr();
				let objParams = {};
				objParams = {
				  "bookEmpId": this.userInfo.empInfo.empId,
				  "corpId":  this.userInfo.empInfo.corpId,
				  "ifViolate": this.isShowWei?1:0,
				  "orderType": this.orderType, // 单据类型
				  "projectId": this.chooseProject.id,
				  "travelEmpIds": cxrEmpIdArr.join(','),
				  costCenterId:costCenterIdArr[0]
				}
				this.$conn.getConn('commu.singleModelMatchSpgz')(objParams,(res)=>{
					this.spgzSuccessAjax(res)
				},(err)=>{
					this.spgzErrorAjax(err)
				})
			},
			// 审批规则成功回调
			spgzSuccessAjax(res){
				this.spgzList = res.data || [];
				if(this.spgzList.length == 0 ){
					this.isWuXuShenPi = true;
				}else{
					// 如果是只有一个审批规则，则直接默认
					if(this.spgzList.length == 1){
						this.getCurrentSpgz(this.spgzList[0]);
					}else if(this.spgzList.length > 1){
						let	filterSpgzList = this.spgzList.filter((spgzItem,spgzIndex)=>{
							return spgzItem.gzid == this.chooseSpgz.gzId
							
						}) || [];
						if(filterSpgzList.length > 0){
							this.getCurrentSpgz(filterSpgzList[0]);
						}else{
							this.getCurrentSpgz({});
						}

						
					}
					
					this.isWuXuShenPi = false;
				}
				this.loadingSpgzList = false;
			},
			//  审批规则失败回调
			spgzErrorAjax(err){
				this.spgzList = [];
				this.getCurrentSpgz({});
				this.isWuXuShenPi = false;
				this.$alert(err.tips,'温馨提示',{
					type:'error'
				})
				this.loadingSpgzList = false;
			},
			
			// 获取当前的审批规则
			getCurrentSpgz(spgzItem){
				this.chooseSpgz.gzId = spgzItem.gzid; // 审批规则id
				this.chooseSpgz.gzmc = spgzItem.gzmc; // 审批规则名称
				this.chooseSpgz.gzdm = spgzItem.gzdm; // 审批规则代码
				this.$emit('chooseSpgz',this.chooseSpgz)
			},
		}
		
	}
</script>

<style scoped lang="scss">
	// .el-card.is-always-shadow{
	// 	border: 1px solid #E6E6E6;
	// 	box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.02);
	// 	border-radius: 0;
	// }
	
	/deep/ .ti-main,.el-card{
		overflow:inherit;
	}
	/deep/ .el-select-dropdown__wrap{
		max-height: 450px ;
		
	}
	.ti-main{
		.title-name{
			font-size: 24px;
			font-weight: bold;
			font-family: '微软雅黑';
			color: #223344;
			line-height: 24px;
			margin-bottom: 44px;
		}
		margin-top: 20px;
		
		/deep/ .el-card__body{
			padding:30px;
		}
			
		/deep/ .el-form-item__label{
			font-size: 12px;
			font-weight: 400;
			font-family: '微软雅黑';
			color: #748197;
		}
		/deep/ .el-input__inner{
			font-size:15px;
			font-weight: 600;
			font-family: '微软雅黑';
			color: #223344;
		}
		.textLeft{
			text-align: left;
		}
		.ti-title{
			background: #F2F8FF;
			font-size: 15px;
			font-weight: bold;
			color: #8F98A9;
			font-family: '微软雅黑';
			padding: 10px 14px;
			margin-bottom: 30px;
		}
		.marTB{
			margin:20px 0;
		}
		.marTop20{
			margin-top: 20px;
		}
		.marBtm20{
			margin-bottom: 20px;
		}
		.marBtm10{
			margin-bottom: 10px;
		}
		.pointer{
			cursor: pointer;
		}
		
		.costcenter-div{
			.ti-m-ccr{
				position: absolute;
				right: 0;
				left: 0;
				
				// border-bottom:1px solid #C4CDDD;
				line-height: 30px;
				height: 32px;
				display: inline-block;
				width:20%;
				text-align: left;
				z-index: 1001;
				font-size:15px;
				font-weight: 600;
				font-family: '微软雅黑';
				color: #223344;
				bottom: 0;
			}
			
			/deep/ .el-input__inner{
				padding-left: 20%;
			}
			
		}
		
	
		.bfb-w100{
			width: 100%;
		}
		.spanlr{
			padding:0 10px;
			color:#006BB9;
			font-size: 15px;
		}
		.marBtm30{
			margin-bottom: 30px;
		}
		/deep/ .el-input__inner{
			border: 0;
			padding: 0;
			border-radius: 0;
			border-bottom: 1px solid #C4CDDD;
			font-size: 15px;
		}
	}
</style>
