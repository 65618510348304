<template>
	<div class="ta-main">
		<!-- 选择出差申请单 -->
		<el-input prefix-icon="el-icon-s-order" 
		style="cursor: pointer;margin-right: 15px;" 
		class="ta-input-width pointer" 
		v-model="chooseCcsqdInfo.ccsqdNo" 
		placeholder="请选择出差申请单" 
		@click.native="openDialogBox(true)" ></el-input>
		
		<!-- 选择行程 -->
		<el-select v-model="currentRouteId" 
		style="margin-right: 15px;"
		placeholder="请选择行程信息" v-if="isShowTravel" 
		class="ta-se-width" @change="chooseOneTrip($event)">
		    <el-option
		      v-for="item in allRouteList"
		      :key="item.routeId"
		      :label="(item.proType!=10903?`${item.departDate}\xa0\xa0${item.departCityName}--${item.arriveCityName}`:`${item.departDate}\xa0\xa0${item.departCityName}` )"
		      :value="item.routeId"
			  >
			  <span class="iconfont yj-icon-airplane-full" v-show="item.proType == 10901 || item.proType == 10902"></span>
			  <span class="iconfont yj-icon-jiudian1"  v-show=" item.proType == 10903"></span>
			  <span class="iconfont yj-icon-train2huoche" v-show=" item.proType == 10904"></span>
			  
			  <span class="iconfont yj-icon-car-full" v-show=" item.proType == 10906"></span>
			  
			  <span style="margin-left: 10px;">{{item.departDate}}</span>
			  <span style="margin-left: 10px;">{{item.departCityName}}--{{item.arriveCityName}}</span>
			  <span style="margin-left: 10px;font-size: 12px;">{{item.routeStatus | filterRouteStatus}}</span>
		    </el-option>
		</el-select>
		
		<!-- 选择出行人 -->
		<el-checkbox-group  
			ref="valBox"
			v-if="isShowTravel" 
			v-model="checkedList" 
			class="ta-ck-main" 
			:class="{'ta-ck-width':totalWdith>385}"
			@change="chooseCxrList">
				<el-checkbox 
					ref="valLength" 
					v-for="item in routeCxrList" 
					:key="item.empId" 
					:label="item.empId" 
					>{{item.cxrName}}</el-checkbox>
		</el-checkbox-group>
		
		<!-- 计划行程显示 -->
		<span style="font-size:14px;line-height: 30px;" v-if="isShowTravel">
			行程预算：
			<span style="width:10px;"></span>
			{{currentRouteInfo && currentRouteInfo.fyys > -1?'¥'+currentRouteInfo.fyys:'¥--'}}
		</span>
		
		
		
		
		<!-- 选择出差申请单弹窗  -->
		<el-dialog
		    title="出差申请单"
		    :visible.sync="dialogVisible"
			:close-on-click-modal="false"
		    width="880px"
		 >
			<el-input v-model="inputType" placeholder="目的地、出行人、随行人、出差单号"  style="width:50%"><el-button slot="append" icon="el-icon-search" @click.native="searchCcsqd"></el-button></el-input> 
			<span class="ta-ft-right">
				申请时间
				<el-select v-model="monthValue" placeholder="请选择" class="ta-select" @change="changeValue(monthValue)">
				    <el-option
				      v-for="item in monthList"
				      :key="item.key"
				      :label="item.label"
				      :value="item.value"
					  >
				    </el-option>
				</el-select>
			</span>
		    <el-table :data="ccsqdList" 
				:header-cell-style="{ backgroundColor: 'rgba(64,160,255,0.1)'}" 
				:row-class-name="ccsqdTableRowClassName"
				v-loading = "loading"
				element-loading-background = "rgba(255, 255, 255, .9)"
				element-loading-text = "加载中，请稍后..."
				element-loading-spinner = "el-icon-loading"
			
				class="ta-table">
				<el-table-column  width="50">
					<template slot-scope="scope" >
						<el-radio v-model="chooseCcsqdId" :label="scope.row.ccsqdId" :disabled="scope.row.changeCount == 2" @click.native="chooseOneApplyForm($event,scope.row)"><span></span></el-radio>
					</template>
				</el-table-column>
				<el-table-column prop="ccsqdNo" label="申请单号" width="150">
					<template slot-scope="scope">
						<span class="ta-open-ccsqd" @click="openCcsqd(scope.row)">
							{{scope.row.ccsqdNo}}
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="ccrAndSxr" show-overflow-tooltip label="出行人" width="150">
					
				</el-table-column>
				<el-table-column  label="目的地" width="100" show-overflow-tooltip>
					<template slot-scope="scope">
						<span v-if="scope.row.customizeFlag==2">
							{{scope.row.manyMdd}}
						</span>
						<span v-else>
							{{scope.row.mdd}}
						</span>
					</template>
				</el-table-column>
				<el-table-column prop="dateBegin" label="出差时间" width="170" >
					<template slot-scope="scope">{{scope.row.dateBegin}}至{{scope.row.dateEnd}}</template>
				</el-table-column>
				<el-table-column prop="ccsy" label="出差事由" width="130"></el-table-column>
				<el-table-column prop="zys" label="总预算" width="90" >
					<template slot-scope="scope">
						<span class="ystext">{{scope.row.zys | toFixed}}</span>	
					</template>
				</el-table-column>
		    </el-table>
			<el-pagination
				v-if="pageInfo.total"
				background
			    @current-change="handleCurrentChange"
			    :current-page.sync="pageInfo.pageNum"
			    :page-size="pageInfo.count"
			    layout="total, prev, pager, next"
			    :total="pageInfo.total">
			</el-pagination>
			<span slot="footer" class="dialog-footer">
			  <el-button @click="openDialogBox(false)">取 消</el-button>
			  <el-button type="primary" @click="determine">确 定</el-button>
			</span>
		</el-dialog>		
	</div>
</template>

<script>
	import { mapState } from 'vuex';
	export default{
		name: 'TravelApply',
		data() {
			return {
				totalWdith:0, // 出行人文本宽度
				inputType: '', //请输入目的地或出行人或随行人或出差单号  搜索符合条件出差申请单
				dateStart: '', //开始时间
				monthList:[
					{
						value: 'oneMonth',
					    label: '一个月内'
					}, {
					    value: 'twoMonth',
					    label: '两个月内'
					}, {
					    value: 'threeMonth',
					    label: '三个月内'	
					}		
				], // 处理3个月时间段内数据列表
				monthValue: 'threeMonth', // 默认选择1个月内出差申请单
				
				temporaryCcsqdInfo:{}, //临时选中的出差申请单信息
				
				dialogVisible: false, // 出差申请单弹框，默认关闭
				chooseCcsqdInfo: {}, //选中出差申请单后  赋值对象
				chooseCcsqdId: '', //选中出差申请单Id  
				ccsqdList: [],  // 出差申请单列表
			
				currentRouteId: '',//当前行程信息 id
				currentRouteInfo: {}, //多选行程选中其中一项
				allRouteList: [], //所有行程信息列表
				
				isShowTravel:false, //是否显示行程信息
				routeCxrList:[], //当前行程中的出行人列表
				checkedList:[], //默认全选出行人
				cxrListForChoosed:[], // 选中的出行人列表
				pageInfo:{ //出差申请单分页信息
					total:0,
					count:10,
					pageNum:1,
				},
				lsRouteId:'', // 临时行程id
				loading: false, // 加载状态
				
				// 模拟数据
				"totalTrip": { 
					"cityList": [
						{ "airportCode": "PEK", "airportName": "萧山", "cityId": "10216", "cityName": "杭州" } 
						,{ "airportCode": "SHA", "airportName": "天河", "cityId": "10458", "cityName": "武汉" }
						],
					"routeId": "7a84aa0b903b46e6ad99addec09cde36" ,
				}
			}
		},
		/*
		 *   1.出差申请单差旅标准如何匹配？
		 *    先根据申请单行程上预算，再到舱位，最后才是职级
		 *   
		 *   2.页面显示，当前按照谁的差旅标准
		 *      a.在选出行人之前，如果要显示，就显示登录人
		 *		b.如果选了申请单+行程后，行程上有预算、舱位，直接显示「当前按照申请单预算标准」
		 *      c.如果没有预算、舱位，显示最低职级那个
		 */ 
		props:{
			// 产品类型
			proType:{
				type: [String,Number],
				default: "",
			},
			// 出差申请单id或者单号
			ccsqdNoOrId:{
				type: [String],
				default: "",
			},
			// 行程id
			routeId:{
				type: [String],
				default: "",
			},
		
		},
		watch:{
			 routeCxrList(value, event) {
				// 计算文本宽度， 超出换行 时要 处理行高 
				this.totalWdith = 0;
			    // this.$nextTick()不能缺少，不然第一次加载宽度为0
			    this.$nextTick(function() {
			        this.valBoxWidth = this.$refs.valBox?this.$refs.valBox.offsetWidth: 0;
			        let length = 0;
			        if (this.routeCxrList.length > 0) {
						length = this.routeCxrList.length;
						for (let i = 0; i < length; i++) {
							this.totalWdith+=this.$refs.valLength[i].$el.offsetWidth+10
						}
			        }
			      });
			},
			// 出差单号
			ccsqdNoOrId(val){
				if(val){
					this.lsRouteId = this.routeId;	
					this.getCcsqdDetail(val,2,this.lsRouteId)
				}
			},	
		},
		computed:{		
			...mapState({
				ccsqdInfo: state => state.common.ccsqdInfo, // 出差申请单信息
			}),
			userInfo(){
				let userInfo = this.$common.getUserInfo();
				return userInfo
			},
			empInfo(){
				return this.userInfo.empInfo
			},
			// 企业模式
			bookStyle(){
				let bookStyle = this.userInfo.vipCorp.bookStyle;
				return bookStyle
				
			},
			// 验证行程已预订，是否可以继续预订？
			isValidRouteAgainBook(){
				return this.$common && this.$common.getVipParamterByParNo(this.$store,'20082', 1);
			},
		},
		filters:{
			// 当前行程状态 行程状态 0 草稿；1审批中；2待预订；3已预订；4审批拒绝
			filterRouteStatus(routeStatus){
				let obj = {
					0:'草稿',1:'审批中',2:'待预订',3:'已预订',4:'审批拒绝'	
				}
				return obj[routeStatus]
			},
			toFixed(val){
				let num = 0;
				if(val){
					return val.toFixed(2)
				}else{
					return 0.00
				}
			}
		},
		mounted() {
			this.lsRouteId = this.routeId;
			this.chooseCcsqdId =  this.ccsqdNoOrId;
			// 获取无变化的出差单
			let chooseCcsqdObj = this.$common.sessionget('chooseCcsqdObj') || {};
		
			// 当存在出差申请单详情和
			if(chooseCcsqdObj.proType && chooseCcsqdObj.proType == this.proType){
				if(chooseCcsqdObj.cxrList&&chooseCcsqdObj.cxrList.length>0){
					this.checkedList = chooseCcsqdObj.cxrList.map((cxrItem)=>{
						return cxrItem.empId
					}) || [];
				}
				if(chooseCcsqdObj.detail){
					this.handlerCcsqdDetail(chooseCcsqdObj.detail)
				}
				
			}else{
				// 单点登录处理
				// 当缓存中存在详情，但是没有指定产品类型，需要主动匹配可能存在的产品类型，
				var reqParams = this.$common.sessionget('reqParams') || {};
				// a.当已经获取详情
				if(this.$common.isObjNotBlank(chooseCcsqdObj.detail)  && reqParams.approvalNo){
					// this.handlerCcsqdDetail(chooseCcsqdObj.detail)
					this.chooseCcsqdInfo = chooseCcsqdObj.detail || {};
					// 默认选中第一条
					this.chuLiCcsqdDetail()
					
				// b.当还没有获取详情 
				}else if(reqParams.approvalNo && !this.$common.isObjNotBlank(chooseCcsqdObj.detail) ){
					// 如果没有获取到出差申请单详情，默认获取下
					//  单号、 行程id
					this.getCcsqdDetail(reqParams.approvalNo,2,reqParams.routeId)
				}
			}

		},
		
		methods: {
			ccsqdTableRowClassName({row, rowIndex}) {
				
				if (row.changeCount == 2) {
				  return 'row-gray-disabled';
				} 
				return '';
			},
			
			
			// 处理出差申请单详情
			handlerCcsqdDetail (ccsqdDetail){
				// 处理已选行程的出差申请单的显示问题
				// this.chooseCcsqdInfo.ccsqdNo = ccsqdDetail.ccsqdNo;
				this.chooseCcsqdInfo = ccsqdDetail;
				// 显示已经选择的行程信息
				this.currentRouteId = this.routeId || '';
				
				// 获取【当前出差单】，所有可以选择的 【出差行程集合】
				this.allRouteList = this.getAllRouteList(ccsqdDetail,this.proType);
				// 获取当前出差行程信息
				// 处理出行人问题 
				// routeCxrList:[], //当前行程中的出行人列表
				// checkedList:[], //默认全选出行人
				
				this.routeCxrList = this.getCxrList(ccsqdDetail,this.routeId,this.checkedList);
				if(this.allRouteList.length>0 && this.routeCxrList.length>0){
					this.isShowTravel = true;
				}
				
			},
			/**
			 *    获取当前出差单中的【可选出行人集合】
			 *    param 1： 出差申请单详情
			 *    param 2： 行程id
			 *    param 3： 选中的出行人id集合
			 * 
			 *    返回值：
			 *        出行人集合
			 * 
			 * 
			 * **/  
			getCxrList(ccsqdDetail,routeId,checkedIdList,proType){
				var cxrList = [];
				let  personList = ccsqdDetail.personList || [];
				this.allRouteList.map((item,index)=>{
					if(item.routeId == routeId){
						this.currentRouteInfo = item;
					}
				})
				// 当前行程的出行人id集合
				let  choosePersonList = this.currentRouteInfo && this.currentRouteInfo.cxrId?this.currentRouteInfo.cxrId.split(','):[];
				// 从所有人中筛选出，本次出行的人
				let routeCxrList = personList.filter((item,index)=>{
					let empId = item.empId;
					// 如果是外来人，处理下出行人属性
					if(item.exPerson == 1){
						item.cxrType = 2
					}else{
						item.cxrType = 1
					}
					// 如果是出差人或者随行人，将出差申请单的成本中心赋给他
						item.costCenterCode =  item.costCenterCode || ccsqdDetail.costCenterCode;
						item.costCenterId = item.costCenterId || ccsqdDetail.costCenterId;
						item.costCenterName = item.costCenterName||  ccsqdDetail.costCenterName;
						item.cxrName = item.empName || item.cxrName;
						// 处理证件问题
						item.idcardList = item.vipIdcardResponses || [];
						// 处理默认选中问题  
						if(checkedIdList && checkedIdList.indexOf(empId) > -1){
							item.checked = true;
						}
					return (choosePersonList.indexOf(empId) > -1)
				})	
				// 格式化出行人数据结构
				cxrList = this.$common.chuLiIdCardList(routeCxrList,proType);
				
				return cxrList 
			},
			handleCurrentChange(e){
				this.pageInfo.pageNum = e;
				this.queryTravelApply();
			},
			// 打开弹框 ，员工选择已经审批通过的出差申请单进行预订
			queryTravelApply(){
				this.loading = true;
				let dateEnd = this.$dateTime.getToDay(); //今入日期	
				let dateStart = !this.dateStart ?this.$dateTime.getDateBegin(-3):this.dateStart;
				let queryString = {
					count:this.pageInfo.count,
					pageNum:this.pageInfo.pageNum,
					proType:this.proType,
					dateBegin: dateStart,
					dateEnd: dateEnd,
					ccr: this.inputType,
					dateType: 1, //1申请日期2出差日期
					status: 2, //0草稿,1审批中,2审批通过,3审批拒绝
					routeEffective:1,
				}
				this.$conn.getConn('travelRequest.vipCcsqdGetList')(queryString,(res)=>{
					let data = res.data || {}
					this.ccsqdList = data.list || [];
					this.pageInfo.total = data.total;
					this.loading = false;
				})
			},
			// 选中一条出差申请单信息
			chooseOneApplyForm(el,row) {
				if(el.target.tagName == 'INPUT'){
					return 
				}
				if(row.changeCount == 2){
					this.$alert('当前申请单，正处于变更中,请审批后，进行选择！')
					return false
				}
				this.chooseCcsqdId = row.ccsqdId;
				this.getCcsqdDetail(this.chooseCcsqdId)
				
			},
			// 获取出差申请单详情
			/**
			 *  场景一： 1【选择出差申请单列表】切换出差单时，获取出差申请单详情，此时仅获取临时出差申请单详情
			 * 
			 *  场景二：2 【默认注入的出差申请单号或者id】
			 *    若存在行程id 和 业务类型
			 * 
			 * 
			 * 
			 * **/ 
			
			getCcsqdDetail(ccsqdId,scene,routeId){
				if(!ccsqdId){
					return false
				}
				
				let obj = {
					ccsqdId:ccsqdId,
					proType:this.proType,
				}
				this.$conn.getConn('travelRequest.travelRequestInfo')(obj,(res)=>{
					let data = res.data || {};
					
					// data.totalTrip= this.totalTrip;
					
					this.chooseCcsqdId = data.id || ccsqdId;
					if(scene == 2){
						this.chooseCcsqdInfo = data;
						this.chooseCcsqdInfo.ccsqdId = data.id || ccsqdId;
						this.chuLiCcsqdDetail(routeId);
					}else{
						this.temporaryCcsqdInfo = data;	
						// 是否预定过
						let isYd = false;
						// 是否 存在当前业务类型行程 默认不存在为true
						let isCz = true; 
						// 查看出差申请单是否预定 已预定 增加提示
						for(var i=0; i<data.allRouteList.length; i++){
							var routeItemObj = data.allRouteList[i][0] || {};
							if( (routeItemObj.proType == this.proType|| routeItemObj.proType=='---') && this.isRouteCanRoute(routeItemObj)  ){
								isCz = false;
								// if(data.allRouteList[i][0].routeStatus==3){
								// 	isYd = true;
								// }
							}
						}
						// 如果当前申请单不存在于当前业务类型，提示一下 ，返回信息赋值会出现undefinde
						if(isCz){
							this.$confirm('您选中的申请单没有【审批通过】的当前业务类型行程，请查证后再选择预订，或者选择其他出差单预订!', '温馨提示', {
							        confirmButtonText: '确定',
									cancelButtonText: '取消',
							        type: 'warning'
							    }); 
						}
						
						//已预定 增加提示
						// if(isYd){
						// 	this.$confirm('您已经预订过该行程，是否重新预订?', '提示', {
						// 	        confirmButtonText: '确定',
						// 	        cancelButtonText: '取消',
						// 	        type: 'warning'
						// 	    }).then(() => {
						// 	        this.determine()
						// 	    }).catch(() => {
							                 
						// 	    });  
							
						// }
					}
				},(err)=>{
					
				})
			},
		
			
			// 确定保存 选中回显出差申请单数据
			determine() {	
				if(JSON.stringify(this.temporaryCcsqdInfo)!='{}'){
					this.chooseCcsqdInfo = this.temporaryCcsqdInfo;	
				}
				
				
				if(JSON.stringify(this.chooseCcsqdInfo)=='{}'){
					// this.dialogVisible = false;
					this.$confirm('请您选择出差申请单!', '提示', {
					        confirmButtonText: '确定',
					        cancelButtonText: '取消',
							showCancelButton: false,
							closeOnClickModal: false,
					        type: 'warning'
					    }).then(() => {
					     
					    }).catch(() => {
					                 
					    });  
					return false;
				}
				this.chuLiCcsqdDetail();
				this.dialogVisible = false;
			},
			// 点击打开出差申请单弹框
			openDialogBox(bool) {
				if(bool==true){
					this.queryTravelApply();
				}
				this.dialogVisible = bool;
			},
			// 选择日期改变当前月份查询出差申请单列表
			changeValue(value){
				if(value=='oneMonth'){
					this.dateStart = this.$dateTime.getDateBegin(-1); // 控制月份 整数向后， 负数向前	
				}else if(value=='twoMonth') {
					this.dateStart  = this.$dateTime.getDateBegin(-2);	
				}else if(value=='threeMonth'){
					this.dateStart  = this.$dateTime.getDateBegin(-3);	
				}
				this.pageInfo.pageNum = 1;
				this.queryTravelApply();
			},
			// 输入查询条件搜索出差申请单
			searchCcsqd(){
				this.pageInfo.pageNum = 1;
				this.queryTravelApply()
			},
			
			
			// 根据已有产品类型，获取当前可以选择的产品行程集合
			getAllRouteList(ccsqdDetail,proTypeDef){
				// 当前出差申请单 所有行程
				let allRouteList = ccsqdDetail?.allRouteList??[];
				// 处理行程信息列表
				let newArr = []; 
				for(let i=0; i<allRouteList.length; i++){
					for(let j=0;j<allRouteList[i].length;j++){
						let routeItem = allRouteList[i][j];
						// 满足当前行程类型，并且是 行程可以预订选择的
						if((routeItem.proType == proTypeDef || routeItem['proType'] == '---') && this.isRouteCanRoute(routeItem ) && this.isRouteCanRouteForBooked(routeItem)  ){
							newArr.push(routeItem);
						}
					}
				
					//  可有，可无
					// allRouteList 数据格式 存在不是自己模块中的数据，再次遍历一遍 allRouteList[[{},{},{}],[{},{}],[{}],[{}]] 而不是[[{}],[{}],[{}]] 所以不能取0
					if(newArr.length==0){
						let routeItemList = allRouteList[i]
						for(var j=0; j<routeItemList.length; j++)
						if((routeItemList[j].proType == proTypeDef || routeItemList[j]['proType'] == '---') && this.isRouteCanRoute(routeItemList[j])  && newArr.length==0){
							newArr.push(routeItemList[j]);
						}
					}
					
					
				}
	
				return newArr
			},
			
			// 是否该行程可以被选，不发生变更
			isRouteCanRoute(routeItem){
				var bool = true;
				//如果行程变更中，或者 
				if(routeItem.isChange == 2  ){
					bool = false;
				}
				return bool
			},
			// 开启企业参数，已预定行程不可继续预订，则不可选择
			isRouteCanRouteForBooked(routeItem){
				var bool = true;
				if(routeItem.routeStatus == 3 && this.isValidRouteAgainBook == 2) {
					bool = false;
				}
				return bool
			},
			
			
			// 根据出差申请单信息  跳转到出差申请单详情
			chuLiCcsqdDetail(routeId){
				// 处理成本中心字段问题
				if(this.chooseCcsqdInfo.costCenter && this.chooseCcsqdInfo.costCenter.length>0){
					this.chooseCcsqdInfo.costCenter = this.chooseCcsqdInfo.costCenter.map(item=>{
						item.costCenterId = item.costId;
						return item
					})
				}
				// 当前出差申请单 所有行程
				let allRouteList = this.chooseCcsqdInfo?.allRouteList??[];
				// 获取所有当前业务产品，可以选择的行程列表集合
				let newArr = this.getAllRouteList(this.chooseCcsqdInfo,this.proType); 
				
				this.allRouteList = newArr;
				
				// 如果存在已选行程id，找出这个行程id，如果没有默认取第一个
				// 业务逻辑要修改，要判断当前行程是不是变更中
					let newRouteArr = newArr.filter((routeItem)=>{
						return  routeItem.routeId == routeId &&  this.isRouteCanRoute(routeItem) && this.isRouteCanRouteForBooked(routeItem)
					}) || [];
					
					if(newRouteArr.length>0){
						this.currentRouteInfo = newRouteArr[0];
						this.currentRouteId = newRouteArr[0]?.routeId || '';
						
					
					}else{
						for(var k=0;k<newArr.length;k++){
							if(this.isRouteCanRoute(newArr[k]) && this.isRouteCanRouteForBooked(newArr[k])){
								this.currentRouteInfo  = newArr[k];
								this.currentRouteId = newArr[k].routeId;
								break;
							}
						}
					
					}
					if(!this.currentRouteId){
						this.$alert('该出差单中,未存在审批通过的当前业务行程，请查证后再进行预订，或者选择其他出差单预订','温馨提示',{
							type:'warning'
						})
						return false
					}
				this.queryRouteInfo(this.currentRouteId);
			},
			// 处理当前行程中出行人信息
			queryRouteInfo(routeId) {	

				let curRouteItem = {};
				this.allRouteList.map((item,index)=>{
					if(item.routeId == routeId){
						curRouteItem = item;
					}
				})

				if (!this.isRouteCanBook(curRouteItem)) {
					
					this.$alert("当前行程正处于变更中吧，不可选择！",'温馨提示',{
							type:"error"
						})
					return false
				}

				// 如果行程状态是  已预订
				if(curRouteItem.routeStatus == 3){
					if(this.isValidRouteAgainBook == 2){
						this.$alert("当前出差行程已经预订,不可继续预订！",'温馨提示',{
							type:"error"
						})
						return false
					}else{
						this.$confirm('当前出差行程已经预订,是否继续预订？', '是否继续预订', {
							confirmButtonText: '继续预订',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {
							// 获取行程详情，并更新行程信息
							this.queryRouteInfoFn(routeId,curRouteItem);
									
						}).catch(() => {
			
						});
					
						
					}
				}else{
					// 获取行程详情，并更新行程信息
					this.queryRouteInfoFn(routeId,curRouteItem);
				}
	
				


	
			},
			// 判断当前出差行程，能否选择预订
			isRouteCanBook(routeItem) {
				// isChange 0 未变更  1 已变更  2 变更中
				var bool = true;
				if (routeItem && routeItem.isChange == 2) {
					bool = false
				}
				return bool
			},

			queryRouteInfoFn(routeId,curRouteItem){
				
				this.lsRouteId = routeId;
				this.isShowTravel = true;
				this.checkedList = [];
				// 出行人总人
				let  personList = this.chooseCcsqdInfo.personList || [];
				
				this.currentRouteInfo = curRouteItem;
			
				// 当前行程的出行人id集合
				let  choosePersonList = this.currentRouteInfo && this.currentRouteInfo.cxrId?this.currentRouteInfo.cxrId.split(','):[];
				// 从所有人中筛选出，本次出行的人
				let routeCxrList = personList.filter((item,index)=>{
					let empId = item.empId;
					// 如果是外来人，处理下出行人属性
					if(item.exPerson == 1){
						item.cxrType = 2
					}else{
						item.cxrType = 1
					}
					// 如果是出差人或者随行人，将出差申请单的成本中心赋给他
						item.costCenterCode =  item.costCenterCode || this.chooseCcsqdInfo.costCenterCode;
						item.costCenterId = item.costCenterId || this.chooseCcsqdInfo.costCenterId;
						item.costCenterName = item.costCenterName||  this.chooseCcsqdInfo.costCenterName;
						item.cxrName = item.empName || item.cxrName;
					
					return (choosePersonList.indexOf(empId) > -1)
				})	
				
				// 处理默认选中问题
				this.routeCxrList  = routeCxrList.map((item,index)=>{
					item.checked = true;
					item.idcardList = item.vipIdcardResponses;
					this.checkedList.push(item.empId);
					return item
				}) || []
				// 格式化出行人数据结构
				this.routeCxrList = this.$common.chuLiIdCardList(this.routeCxrList,this.proType);
				let obj = {
					cxrList: this.routeCxrList, //出行人列表
					detail: this.chooseCcsqdInfo, //出差申请单信息
					proType: this.proType,
					currentRoute: this.currentRouteInfo, //多选行程中的其中一项
					routeId: this.lsRouteId || routeId, //出行id
					ccsqdNo:this.chooseCcsqdInfo.ccsqdNo,
					ccsqdId:this.chooseCcsqdInfo.id,
				}
				this.$common.sessionset('chooseCcsqdObj',obj)
				this.$emit('chooseTravelApply', obj);	
			},

			// 多个行程信息 选择其中一条
			chooseOneTrip(routeId){
				this.queryRouteInfo(routeId)
			},
			// 勾选出行人选中操作
			chooseCxrList(cxrIdList) {
				if(cxrIdList.length==0){
					this.$message({
						type:'warning',
						message:'最少保留一位出行人'
					})
				}
				
				let chooseCxrList  = this.routeCxrList.filter((item)=>{
					return cxrIdList.indexOf(item.empId)>-1
				}) || []
				// 格式化出行人数据结构
				chooseCxrList = this.$common.chuLiIdCardList(chooseCxrList,this.proType);
				
				let obj = {
					cxrList: chooseCxrList, // 出行人列表
					detail: this.chooseCcsqdInfo,  // 出差申请单信息
					proType: this.proType, // 业务类型 机票 火车票
					currentRoute: this.currentRouteInfo, // 当前行程信息
					routeId: this.lsRouteId,  //出行id
					ccsqdNo:this.chooseCcsqdInfo.ccsqdNo, //出差单号
					ccsqdId:this.chooseCcsqdInfo.id, //申请单ID ,
					changeType:'cxrListChange'
				}
				this.$common.sessionset('chooseCcsqdObj',obj)
				this.$emit('chooseTravelApply', obj);
			},
			// 跳转出差申请单详情
			openCcsqd(row){
                let path = row.customizeFlag==2?'/xakCcsqdDetail':'/ccsqdDetail'
				let query = {};
				query = {
					orderId: row.ccsqdId
				}
				let routeUrl = this.$router.resolve({path,query:query});
				window.open(routeUrl.href,'_blank')
			},
		}
	}
</script>

<style scoped lang="scss">
	
	
	
	.ta-main{
		
		text-align: left;
		// display: inline-block;
		display: flex;
		.ta-input-width {
			width: 200px;
		}
		.pointer{
			cursor: pointer;
		}
		.el-checkbox{
			margin-right:10px;
		}
		/deep/ .el-checkbox__label{
			padding-left:2px;
		}
		// .ta-input-width input::-webkit-input-placeholder{
		// 		color: #4096FF;
		// 	}
		// .ta-input-width input::-moz-input-placeholder{
		// 		color: #4096FF;
		// 	}
		// .ta-input-width input::-ms-input-placeholder{
		// 		color: #4096FF;
		// 	} 
		.ta-se-width{
			width: 240px;
		}
		/deep/ .el-table__body-wrapper{
			max-height: 400px;
			overflow-y: auto;
		}
		.ta-table ::v-deep{
			.cell{
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
			}
		}
		/deep/ .el-dialog{
			margin-top: 5vh;
			.el-dialog__body{
				padding: 10px 20px;
			}
		}
		
		.ta-se-width ::v-deep{
				.el-input__inner{
					 // border:none;
					 // color: #4096FF;
				 }
		}	
		.ta-input-width ::v-deep{
				.el-input__inner{
					 // border:none;
					 // color: #4096FF;
				 }
			} 
		/deep/ .el-input__icon{
			height: 34px;
			line-height: 34px;
			
		}		
		.ta-ck-main{
			margin-left:10px;
			display: inline-block;
			line-height: 30px;
			max-width: 385px;
		}
		.ta-ck-width{
			line-height: 16px;
		}
		.ta-ft-right{
			text-align: right;
			float:right;
			.ta-select{
				width: 150px;
			}
		}
		.ta-open-ccsqd{
			color: #40a0ff;
		}
		.ta-open-ccsqd:hover{
			cursor: pointer;
			color: #606266;
		}
		.ystext{
			cursor: pointer;
			display: block;
			padding-right: 10px;
			text-align: right;
		}
		.ystext:hover{
			color: #40a0ff;
		}
	}
	
	
</style>
